import { FC } from 'react';
import Link from 'next/link';
import Image from 'next/image';
import styled from 'styled-components';
import { WebEmployeeSchema } from 'src/__generated__/@types';

type Props = {
  size: 24 | 40 | 64 | 120;
  linkDisabled?: boolean;
} & Pick<WebEmployeeSchema, 'id' | 'avatar' | 'real_name'>;

const Wrapper = styled.div<{
  size: Props['size'];
}>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  flex-shrink: 0;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.colors.base.border.main};

  &:hover {
    border: 2px solid ${({ theme }) => theme.colors.primary.main};
  }
`;

const Icon = styled(Image)`
  border-radius: 50%;
  cursor: pointer;
  object-fit: cover;
`;

/**
 * 従業員のアイコン画像 (`employee.avatar`) を扱う汎用的なコンポーネント
 * @param size - `24px`, `40px`, `64px`, `120px` のみ使用可能
 * @param id - 従業員ごとの投稿一覧画面 (`/employees/[id]`) へのリンクに使用
 * @param avatar - アイコン画像へのパス、デフォルトは `/user_icon_default.png`
 * @param real_name - アイコン画像の `alt` 属性に指定
 * @param linkDisabled - 従業員ごとの投稿一覧画面へのリンクを無効にする、デフォルトfalse
 */
export const UserIcon: FC<Props> = ({
  size,
  id,
  avatar = '/user_icon_default.png',
  real_name,
  linkDisabled = false,
}) => {
  const IconElement = (
    <Icon src={avatar} alt={real_name} width={size} height={size} unoptimized />
  );

  return (
    <Wrapper size={size}>
      {linkDisabled ? (
        IconElement
      ) : (
        <Link href={`/employees/${id}`}>{IconElement}</Link>
      )}
    </Wrapper>
  );
};
