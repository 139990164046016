import { ReactNode, FC } from 'react';
import styled from 'styled-components';
import { Header, type HeaderProps } from './Header';

type Props = {
  children?: ReactNode;
  employee: Omit<HeaderProps, 'tipCounters'>;
  tipCounters: ReactNode;
};

const Container = styled.div`
  --header-height: 80px;

  max-width: ${({ theme }) => theme.breakpoints.xlarge};
  min-height: calc(100vh - var(--header-height));
  margin: 40px auto 0;
`;

export const Layout: FC<Props> = ({
  children,
  employee: { id, role, tenant_name, display_name, avatar },
  tipCounters,
}) => {
  return (
    <>
      <Header
        id={id}
        role={role}
        tenant_name={tenant_name}
        display_name={display_name}
        avatar={avatar}
        tipCounters={tipCounters}
      />
      <Container>{children}</Container>
    </>
  );
};
