import { FC } from 'react';
import styled from 'styled-components';
import { HeaderLogo } from './HeaderLogo';
import { UserMenu } from './UserMenu';
import { EmployeeForHeader } from './types';

export type Props = EmployeeForHeader;

const StyledHeader = styled.header`
  position: sticky;
  z-index: ${({ theme }) => theme.zIndex.header};
  top: 0;
  height: ${({ theme }) => theme.height.header};
  background-color: ${({ theme }) => theme.colors.base.bg.main};
  border-bottom: solid 1px ${({ theme }) => theme.colors.base.border.main};
`;

const Inner = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  max-width: ${({ theme }) => theme.breakpoints.xlarge};
  padding: 0 32px;
  margin: 0 auto;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.medium}) {
    padding: 0 16px;
  }
`;

const Left = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const TipCountersDisplayToggle = styled.div`
  margin-left: 24px;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: none;
  }
`;

export const Header: FC<Props> = ({
  id,
  role,
  tenant_name,
  display_name,
  avatar,
  tipCounters,
}) => (
  <StyledHeader>
    <Inner>
      <Left>
        <HeaderLogo />
        <TipCountersDisplayToggle>{tipCounters}</TipCountersDisplayToggle>
      </Left>
      <Right>
        <UserMenu
          id={id}
          role={role}
          tenant_name={tenant_name}
          display_name={display_name}
          avatar={avatar}
          tipCounters={tipCounters}
        />
      </Right>
    </Inner>
  </StyledHeader>
);
