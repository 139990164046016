import { FC } from 'react';
import styled from 'styled-components';

type Props = {
  monthlyRecieved?: number;
  canSendThisWeek?: number;
};

const FlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 160px;
`;

const TipCounter = styled.div`
  text-align: right;
`;

const Label = styled.div`
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.base.text.sub};
  margin-bottom: 4px;
`;

const TipCount = styled.div<{ primary?: boolean }>`
  --c-primary: ${({ theme }) => theme.colors.primary.main};
  --c-black: ${({ theme }) => theme.colors.base.text.main};

  font-size: 1.5rem;
  font-weight: bold;
  color: ${({ primary }) => (primary ? 'var(--c-primary)' : 'var(-c-black)')};
`;

export const TipCounters: FC<Props> = ({
  monthlyRecieved,
  canSendThisWeek,
}) => (
  <FlexWrapper>
    <TipCounter>
      <Label>今月もらった</Label>
      <TipCount>{monthlyRecieved ?? '???'}</TipCount>
    </TipCounter>
    <TipCounter>
      <Label>今週送れる</Label>
      <TipCount primary>{canSendThisWeek ?? '???'}</TipCount>
    </TipCounter>
  </FlexWrapper>
);
