import { FC } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { Logo } from '../../Logo';

const StyledLink = styled(Link)`
  height: ${({ theme }) => theme.height.header};
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const HeaderLogo: FC = () => (
  <StyledLink href="/">
    <Logo />
  </StyledLink>
);
