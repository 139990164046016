import { FC } from 'react';
import Image, { ImageProps } from 'next/image';
import EmotipLogoHorizontal from 'src/assets/emotip_logo_horizontal.svg';

type Props = {
  size?: number;
};

export const Logo: FC<Props> = ({ size = 1 }) => {
  const imageProps: ImageProps = {
    width: 168 * size,
    height: 32 * size,
    src: EmotipLogoHorizontal,
    alt: 'エモチップのロゴ',
  };

  return <Image {...imageProps} />;
};
