import { FC, useState, useCallback } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faLock, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { UserIcon } from '../../UserIcon';
import { EmployeeForHeader } from './types';
import { useLogout } from '@hooks';

type Props = EmployeeForHeader;

const MenuContainer = styled.div`
  --toggle-menu-width: 216px;

  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.userMenu};
  top: calc(100% + 10px);
  left: calc(-1 * (var(--toggle-menu-width)) + 40px + 4px);
  width: var(--toggle-menu-width);
  border: 1px solid ${({ theme }) => theme.colors.base.border.main};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.colors.base.bg.main};
  box-shadow: ${({ theme }) => theme.shadow.base.main};
`;

const ProfileContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px 24px;
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.base.border.main};
`;

const TenantName = styled.span`
  color: ${({ theme }) => theme.colors.base.text.sub};
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const UserName = styled(Link)`
  display: block;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
    cursor: pointer;
  }
`;

const TipCountersDisplayToggle = styled.li`
  display: none;

  @media screen and (max-width: ${({ theme }) => theme.breakpoints.small}) {
    display: block;
  }
`;

const List = styled.ul`
  border-bottom: 1px solid ${({ theme }) => theme.colors.base.border.main};
`;

const ListItem = styled.li`
  width: 100%;
  padding: 16px 24px;
  margin: 8px 0;
`;

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
    cursor: pointer;
  }
`;

const LogoutButton = styled.button`
  display: flex;
  align-items: center;

  &:hover {
    color: ${({ theme }) => theme.colors.primary.main};
    cursor: pointer;
  }
`;

const ListIconWrapper = styled.div`
  width: 1.5rem;
  flex-shrink: 0;
`;

const Icon = styled(FontAwesomeIcon)`
  font-size: 1.5rem;
`;

const Label = styled.div`
  margin-left: 8px;
`;

const Wrapper = styled.div`
  position: relative;
  margin-left: 16px;
`;

const Cover = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: ${({ theme }) => theme.zIndex.userMenuCover};
`;

export const UserMenu: FC<Props> = ({
  id,
  role,
  tenant_name,
  display_name,
  avatar,
  tipCounters,
}) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const handleClick = useCallback(() => setMenuOpen(!menuOpen), [menuOpen]);
  const logout = useLogout();

  return (
    <Wrapper>
      <button onClick={handleClick}>
        <UserIcon
          avatar={avatar}
          size={40}
          id={1}
          real_name={display_name}
          linkDisabled
        />
      </button>
      {menuOpen && (
        <>
          <MenuContainer>
            <ProfileContainer>
              <div>
                <TenantName>{tenant_name}</TenantName>
                <UserName href={`/employees/${id}`}>{display_name}</UserName>
              </div>
              <TipCountersDisplayToggle>{tipCounters}</TipCountersDisplayToggle>
            </ProfileContainer>
            <List>
              <ListItem>
                <StyledLink href={'/employee_settings/edit'}>
                  <ListIconWrapper>
                    <Icon icon={faCog} />
                  </ListIconWrapper>
                  <Label>プロフィール設定</Label>
                </StyledLink>
              </ListItem>
              {role === 'admin' && (
                <ListItem>
                  <StyledLink href={'/tenant_setting/tenant/edit'}>
                    <ListIconWrapper>
                      <Icon icon={faLock} />
                    </ListIconWrapper>
                    <Label>事業者設定</Label>
                  </StyledLink>
                </ListItem>
              )}
            </List>
            <ul>
              <ListItem>
                <LogoutButton onClick={logout}>
                  <ListIconWrapper>
                    <Icon icon={faSignOutAlt} />
                  </ListIconWrapper>
                  <Label>ログアウト</Label>
                </LogoutButton>
              </ListItem>
            </ul>
          </MenuContainer>
          <Cover onClick={handleClick} />
        </>
      )}
    </Wrapper>
  );
};
