import { FC, useRef, useState, useEffect } from 'react';
import { createPortal } from 'react-dom';
import styled, { keyframes } from 'styled-components';

// https://projects.lukehaas.me/css-loaders/ より引用
const loadingAnimation = keyframes`
  0%,
  100% {
    box-shadow: 0em -2.6em 0em 0em #ff630d, 1.8em -1.8em 0 0em rgba(255,99,13, 0.2), 2.5em 0em 0 0em rgba(255,99,13, 0.2), 1.75em 1.75em 0 0em rgba(255,99,13, 0.2), 0em 2.5em 0 0em rgba(255,99,13, 0.2), -1.8em 1.8em 0 0em rgba(255,99,13, 0.2), -2.6em 0em 0 0em rgba(255,99,13, 0.5), -1.8em -1.8em 0 0em rgba(255,99,13, 0.7);
  }
  12.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,99,13, 0.7), 1.8em -1.8em 0 0em #ff630d, 2.5em 0em 0 0em rgba(255,99,13, 0.2), 1.75em 1.75em 0 0em rgba(255,99,13, 0.2), 0em 2.5em 0 0em rgba(255,99,13, 0.2), -1.8em 1.8em 0 0em rgba(255,99,13, 0.2), -2.6em 0em 0 0em rgba(255,99,13, 0.2), -1.8em -1.8em 0 0em rgba(255,99,13, 0.5);
  }
  25% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,99,13, 0.5), 1.8em -1.8em 0 0em rgba(255,99,13, 0.7), 2.5em 0em 0 0em #ff630d, 1.75em 1.75em 0 0em rgba(255,99,13, 0.2), 0em 2.5em 0 0em rgba(255,99,13, 0.2), -1.8em 1.8em 0 0em rgba(255,99,13, 0.2), -2.6em 0em 0 0em rgba(255,99,13, 0.2), -1.8em -1.8em 0 0em rgba(255,99,13, 0.2);
  }
  37.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,99,13, 0.2), 1.8em -1.8em 0 0em rgba(255,99,13, 0.5), 2.5em 0em 0 0em rgba(255,99,13, 0.7), 1.75em 1.75em 0 0em #ff630d, 0em 2.5em 0 0em rgba(255,99,13, 0.2), -1.8em 1.8em 0 0em rgba(255,99,13, 0.2), -2.6em 0em 0 0em rgba(255,99,13, 0.2), -1.8em -1.8em 0 0em rgba(255,99,13, 0.2);
  }
  50% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,99,13, 0.2), 1.8em -1.8em 0 0em rgba(255,99,13, 0.2), 2.5em 0em 0 0em rgba(255,99,13, 0.5), 1.75em 1.75em 0 0em rgba(255,99,13, 0.7), 0em 2.5em 0 0em #ff630d, -1.8em 1.8em 0 0em rgba(255,99,13, 0.2), -2.6em 0em 0 0em rgba(255,99,13, 0.2), -1.8em -1.8em 0 0em rgba(255,99,13, 0.2);
  }
  62.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,99,13, 0.2), 1.8em -1.8em 0 0em rgba(255,99,13, 0.2), 2.5em 0em 0 0em rgba(255,99,13, 0.2), 1.75em 1.75em 0 0em rgba(255,99,13, 0.5), 0em 2.5em 0 0em rgba(255,99,13, 0.7), -1.8em 1.8em 0 0em #ff630d, -2.6em 0em 0 0em rgba(255,99,13, 0.2), -1.8em -1.8em 0 0em rgba(255,99,13, 0.2);
  }
  75% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,99,13, 0.2), 1.8em -1.8em 0 0em rgba(255,99,13, 0.2), 2.5em 0em 0 0em rgba(255,99,13, 0.2), 1.75em 1.75em 0 0em rgba(255,99,13, 0.2), 0em 2.5em 0 0em rgba(255,99,13, 0.5), -1.8em 1.8em 0 0em rgba(255,99,13, 0.7), -2.6em 0em 0 0em #ff630d, -1.8em -1.8em 0 0em rgba(255,99,13, 0.2);
  }
  87.5% {
    box-shadow: 0em -2.6em 0em 0em rgba(255,99,13, 0.2), 1.8em -1.8em 0 0em rgba(255,99,13, 0.2), 2.5em 0em 0 0em rgba(255,99,13, 0.2), 1.75em 1.75em 0 0em rgba(255,99,13, 0.2), 0em 2.5em 0 0em rgba(255,99,13, 0.2), -1.8em 1.8em 0 0em rgba(255,99,13, 0.5), -2.6em 0em 0 0em rgba(255,99,13, 0.7), -1.8em -1.8em 0 0em #ff630d;
  }
`;

export const Spinner = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.loading};
  top: 200px;
  left: 0;
  right: 0;
  margin: auto;
  width: 1rem;
  height: 1rem;
  border-radius: 50%;
  animation: ${loadingAnimation} 1.1s infinite ease;
`;

const Cover = styled.div`
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.loadingCover};
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.base.bg.modal};
  /**
   * 背景をぼかす。firefox 未対応。
   * ref: https://developer.mozilla.org/ja/docs/Web/CSS/backdrop-filter
   */
  backdrop-filter: ${({ theme }) => theme.blur.modal};
`;

export const Loading: FC = () => {
  const ref = useRef<HTMLElement>();
  const [mounted, setMounted] = useState(false);

  /**
   * ReferenceError: document is not defined を防ぐために、DOM がマウントされたことを保証する。
   * ref: https://github.com/vercel/next.js/tree/canary/examples/with-portals
   */
  useEffect(() => {
    ref.current = document.body;
    setMounted(true);
  }, []);

  return mounted && ref.current
    ? createPortal(
        <Cover>
          <Spinner />
        </Cover>,
        ref.current
      )
    : null;
};
